<mat-option [formGroup]="ngxForm">
  <ngx-mat-select-search
    placeholderLabel="Search..."
    formControlName="formSharedValuesFilter"
    [noEntriesFoundLabel]="''"
    [attr.data-testid]="'shared-values-search'"
    [attr.id]="'shared-values-search'"
  >
    <span aria-hidden="true" class="close" ngxMatSelectSearchClear>
      <i class="fa-solid fa-xmark"></i>
    </span>
  </ngx-mat-select-search>
</mat-option>
@if (noMatch) {
  <div class="w-full !h-8 !pl-4 flex items-center">
    <span>Not match found...</span>
  </div>
}
