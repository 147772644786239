import { CommonModule } from '@angular/common';
import {
  Component,
  effect,
  Input,
  input,
  OnDestroy,
  OnInit,
  output,
  signal,
} from '@angular/core';
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { map, takeUntil, Subject, startWith } from 'rxjs';
import {
  FieldDataType,
  FieldsFromBucket,
  StationBucketQuestion,
  StationLibraryRelationship,
  Question,
  StationRosterMember,
  UpdatedFieldNoQuestions,
  CustomField,
} from 'src/models';
import _ from 'lodash';
/** Ngx search component. */
@Component({
  selector: 'app-ngx-search',
  standalone: true,
  imports: [
    CommonModule,
    NgxMatSelectSearchModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
  ],
  templateUrl: './ngx-search.component.html',
  styleUrl: './ngx-search.component.scss',
})
export class NgxSearchComponent implements OnInit, OnDestroy {
  /** Destroyed. */
  private destroyed$ = new Subject<void>();

  /** Static element fields. */
  staticDataFields = input<UpdatedFieldNoQuestions[]>([]);

  /** Custom fields UpdatedFieldNoQuestions. */
  customUpdatedFieldNoQuestions = input<UpdatedFieldNoQuestions[]>([]);

  /** Question bucket data. */
  questionBucket = input<FieldsFromBucket[]>([]);

  /** Station roster members data. */
  stationRosterMember = input<StationRosterMember[]>([]);

  /** Questions data. */
  questions = input<Question[]>([]);

  /** The station bucket question data. */
  stationBucketQuestion = input<StationBucketQuestion[]>([]);

  /** The station bucket question data. */
  stationLibraryRelationships = input<StationLibraryRelationship[]>([]);

  /** Custom fields. */
  customField = input<CustomField[]>([]);

  /** Multiple questions options. */
  dataMultipleQuestion = signal<Question[][]>([]);

  /**
   * Set multiple questions input.
   */
  @Input() set multipleQuestions(questions: Question[][]) {
    if (questions.length) {
      this.dataMultipleQuestion.set(questions);
      this.ngxForm.controls.formSharedValuesFilter.setValue('');
    }
  }

  /** The form to add this field in the template. */
  ngxForm: FormGroup = new FormGroup({
    formSharedValuesFilter: new FormControl<string>(''),
  });

  /** Emit array filtered. */
  getFilteredArrays = output<FieldDataType>();

  /** No match label. */
  noMatch = false;

  constructor() {
    effect(() => {
      this.questions();
      this.ngxForm.controls.formSharedValuesFilter.setValue('');
    });
  }

  /** Init life cycle. */
  ngOnInit(): void {
    this.listenFieldFormAutoComplete$();
  }

  /**
   * Filter arrays with the value.
   * @param value Value to search.
   * @param objects Object to filter.
   * @param propertyFilter Property where search.
   * @param fieldDataTypeProperty Property FieldDataType.
   * @returns Array filtered.
   */
  filterNgxSelect<T>(
    value: string,
    objects: T[],
    propertyFilter: keyof T,
    fieldDataTypeProperty: keyof FieldDataType,
  ): FieldDataType {
    const object = {
      [fieldDataTypeProperty]: objects.filter((obj) => {
        const propValue = obj[propertyFilter];
        return (
          typeof propValue === 'string' &&
          propValue.toLowerCase().includes(value?.toLowerCase())
        );
      }),
    };
    return object;
  }

  /**
   * Listen for changes to filterUpdatesTo for autocomplete.
   */
  private listenFieldFormAutoComplete$(): void {
    this.ngxForm.controls.formSharedValuesFilter.valueChanges
      .pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : '')),
        takeUntil(this.destroyed$),
      )
      .subscribe((value) => {
        let filtered = {
          ...this.filterStaticField(value),
          ...this.filterCustomFieldNoQuestions(value),
          ...this.filterQuestionBucket(value),
          ...this.filterStationRosterMember(value),
          ...this.filterQuestions(value),
          ...this.filterStationBucketQuestion(value),
          ...this.filterStationLibraryRelationship(value),
          ...this.filterCustomField(value),
        };
        this.noMatch =
          Object.values(filtered)
            .filter(Array.isArray)
            .reduce((sum, arr) => sum + arr.length, 0) === 0;
        // If there is a multi-array of questions.
        if (this.dataMultipleQuestion().length) {
          filtered = {
            ...filtered,
            ...this.filterMultipleQuestions(value),
          };
          // If there is a multi array of questions and there is no match.
          if (filtered.multipleQuestions?.length && this.noMatch)
            // We verify that there is data in the multi array.
            this.noMatch =
              filtered.multipleQuestions.reduce((sum, arr) => {
                return sum + arr.length;
              }, 0) === 0;
        }
        // We set the value for if there is a match in a general way.
        filtered.noMatchElements = this.noMatch;
        this.getFilteredArrays.emit(filtered);
      });
  }

  /**
   * Filter array static field.
   * @param value Value to compare.
   * @returns Object FieldDataType.
   */
  filterStaticField(value: string): FieldDataType {
    return this.staticDataFields().length
      ? this.filterNgxSelect(
          value,
          this.staticDataFields(),
          'name',
          'staticDataFields',
        )
      : {};
  }

  /**
   * Filter array custom field no questions.
   * @param value Value to compare.
   * @returns Object FieldDataType.
   */
  filterCustomFieldNoQuestions(value: string): FieldDataType {
    return this.customUpdatedFieldNoQuestions().length
      ? this.filterNgxSelect(
          value,
          this.customUpdatedFieldNoQuestions(),
          'name',
          'customUpdatedFieldNoQuestions',
        )
      : {};
  }

  /**
   * Filter array questions bucket field.
   * @param value Value to compare.
   * @returns Object FieldDataType.
   */
  filterQuestionBucket(value: string): FieldDataType {
    return this.questionBucket().length
      ? this.filterNgxSelect(
          value,
          this.questionBucket(),
          'prompt',
          'questionBucket',
        )
      : {};
  }

  /**
   * Filter array station roster member.
   * @param value Value to compare.
   * @returns Object FieldDataType.
   */
  filterStationRosterMember(value: string): FieldDataType {
    return this.stationRosterMember().length
      ? this.filterNgxSelect(
          value,
          this.stationRosterMember(),
          'firstName',
          'stationRosterMember',
        )
      : {};
  }

  /**
   * Filter array station bucket question.
   * @param value Value to compare.
   * @returns Object FieldDataType.
   */
  filterStationBucketQuestion(value: string): FieldDataType {
    return this.stationBucketQuestion().length
      ? this.filterNgxSelect(
          value,
          this.stationBucketQuestion(),
          'prompt',
          'stationBucketQuestion',
        )
      : {};
  }

  /**
   * Filter array questions.
   * @param value Value to compare.
   * @returns Object FieldDataType.
   */
  filterQuestions(value: string): FieldDataType {
    return this.questions().length
      ? this.filterNgxSelect(value, this.questions(), 'prompt', 'questions')
      : {};
  }

  /**
   * Filter array custom field.
   * @param value Value to compare.
   * @returns Object FieldDataType.
   */
  filterCustomField(value: string): FieldDataType {
    return this.customField().length
      ? this.filterNgxSelect(value, this.customField(), 'prompt', 'customField')
      : {};
  }

  /**
   * Filter array multiple questions.
   * @param value Value to compare.
   * @returns Object FieldDataType.
   */
  filterMultipleQuestions(value: string): FieldDataType {
    const dataQuestions: Question[][] = [];
    for (let k = 0; k < this.dataMultipleQuestion().length; k++) {
      if (this.dataMultipleQuestion()[k]) {
        const filteredQuestions = this.filterNgxSelect(
          value,
          this.dataMultipleQuestion()[k],
          'prompt',
          'questions',
        );
        dataQuestions.push(filteredQuestions.questions || []);
      }
    }
    return {
      multipleQuestions: dataQuestions,
    };
  }

  /**
   * Filter array station library relationship.
   * @param value Value to compare.
   * @returns Object FieldDataType.
   */
  filterStationLibraryRelationship(value: string): FieldDataType {
    if (this.stationLibraryRelationships().length) {
      const copyRelationships = _.cloneDeep(this.stationLibraryRelationships());
      const lowerCaseValue = value.toLowerCase();
      //  We use reduce to iterate over stationLibraryRelationships.
      const filteredStationLibraryRelationships = copyRelationships.reduce(
        (filtered, item) => {
          // We filter the relationships that match the search criteria.
          const matchingRelationships = item.relationships.filter(
            (relationship) => {
              const inwardMatch =
                relationship.inwardName &&
                relationship.inwardName.toLowerCase().includes(lowerCaseValue);
              const outwardMatch =
                relationship.outwardName &&
                relationship.outwardName.toLowerCase().includes(lowerCaseValue);

              // If both match, we keep both.
              if (inwardMatch && outwardMatch) {
                return true;
              }
              // If there is a match in inwardName, we empty outwardName and vice versa.
              if (inwardMatch) {
                relationship.outwardName = '';
              } else if (outwardMatch) {
                relationship.inwardName = '';
              }
              return inwardMatch || outwardMatch;
            },
          );
          //  If there are matches, we add the element to the filtered array.
          if (matchingRelationships.length > 0) {
            filtered.push({ ...item, relationships: matchingRelationships });
          }
          return filtered;
        },
        [] as StationLibraryRelationship[],
      );
      return {
        stationLibraryRelationship: filteredStationLibraryRelationships,
      };
    } else {
      return {};
    }
  }

  /**
   * Cleanup method.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
